<template>
  <div>
    <Header :customstyle="{background: '#0C1913'}">
		<template slot="title">转入</template>
	</Header>
    <div class="content">
        <div class="topcontent">
            <div class="title">请选择转入资产</div>
            <div class="topupnumber">
                <div v-for="currency in currencys" :key="currency">
                    <div class="btn" :class="currency==chooseTypeText?'active':''" @click="tochooseType(currency)" v-if="currency=='ATT'">
                        <div>
                            {{currency}}
                        </div>
                        <div style="font-size: 12px;">薄饼</div>
                    </div>
                    <div class="btn" :class="currency==chooseTypeText?'active':''" @click="tochooseType(currency)" v-else-if="currency=='ATTNEW'">
                        <div>
                            ATT
                        </div>
                        <div style="font-size: 12px;">交易所</div>
                    </div>
                    <div class="btn" :class="currency==chooseTypeText?'active':''" @click="tochooseType(currency)" v-else>
                        {{currency}}
                    </div>
                </div>
            </div>
            <div class="title offset">请选择转入数量</div>
            <div class="topupnumber">
                <div v-for="num in topnum" :key="num">
                    <div class="btn"  :class="num==inputtopup?'active':''" @click="chooseNum(num)">
                        {{num}}
                    </div>
                </div>
            </div>
            <div class="inputcontent">
                <van-field class="inputClass" v-model="inputtopup"  placeholder="请输入转入数量" />
            </div>
            <div class="topresult">
                <div class="expectpay">
                    <div class="num">{{ inputtopup||0 }}</div>
                    <div class="txt" v-if="chooseTypeText=='ATTNEW'">预期支付(ATT交易所)</div>
                    <div class="txt" v-else>预期支付({{ chooseTypeText }})</div>
                </div>
                <div class="expectpay">
                    <div class="num" v-if="chooseType==0">{{ parseFloat(eptBanner).toFixed(2) }}</div>
                    <div class="num" v-if="chooseType==1">{{  parseFloat(arrBanner).toFixed(4) }}</div>
                    <div class="num" v-if="chooseType==2">{{ parseFloat(usdtBanner).toFixed(2)  }}</div>
                    <div class="num" v-if="chooseType==3">{{ parseFloat(attBanner).toFixed(2)  }}</div>
                    <div class="num" v-if="chooseType==4">{{ parseFloat(btc3Banner).toFixed(2)  }}</div>
                    <div class="num" v-if="chooseType==5">{{ parseFloat(att2Banner).toFixed(2)  }}</div>
                    <div class="txt" v-if="chooseTypeText=='ATTNEW'">钱包余额(ATT交易所)</div>
                    <div class="txt" v-else>钱包余额({{ chooseTypeText }})</div>
                </div>
            </div>
            <div class="paybtn">
                <div class="btn" @click="toSubmitCharge($event)">
                    确定
                </div>
            </div>
        </div>
        <div class="middlecontent">
            温馨提示 <br/>
            1.您的转入需要经过区块确认 <br/>
            2.转入到账时间预计8-10分钟，请耐心等候，8-10分钟可在右上角三点进行刷新操作<br/>
            3. ATT充值额外获得6.25%共识度和自身级别手续费奖励以及100%可提额度。操作流程：转入→ATT →填写数量→充值
        </div>
        <div class="record">
            <div class="title">转入记录</div>
            <div class="datalist">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多记录了"
                    @load="onLoad"
                    >
                        <div class="cell" v-for="(item, index) in recordList" :key="index">
                            <div class="detail">
                                <div class="topnum">
                                    <div class="num">
                                        {{parseFloat(item.amount).toFixed(4)}}
                                    </div>
                                    <div class="txt">
                                        转入数量({{item.coin_symbol}})
                                    </div>
                                </div>
                                <div class="topnum">
                                    <div class="time">
                                        时间：{{item.timestamp}}
                                    </div>
                                    <!-- <div class="time">
                                        支付数量：{{item.num}}
                                    </div> -->
                                </div>
                                <div class="status">
                                    {{ getTransferTxt (item.transfer_status)}}
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import {chargeLog} from '@/request/api'
import { ethers } from '../../components/ethers-5.1.esm.min.js'
import metamaskConfig from '../../components/connection.js'
import { Toast } from 'vant';
import $ from 'jquery'
export default {
    components:{
        Header
    },
    computed : {
        getTransferTxt(){
            return val => {
                let txt = ''
                switch (val) {
                    case -1:
                        txt = '失败(拒绝)'
                        break
                    case 0:
                        txt = '等待处理'
                        break
                    case 1:
                        txt = '成功'
                        break
                    case 2:
                        txt = '转账中'
                        break
                }
                return txt
            }
        }
    },
    data() {
        return {
            refreshing: false,
            loading: false,
            finished: false,
            topnum:[
                '10', '50','200','500'
            ],
            inputtopup:'',
            currencys:[
               'ATTNEW', 'ATT','USDT','BTC3.0 LP'
            ],
            recordList: [
            ],
            currentPage: 1,
            chooseType:5,
            chooseTypeText:'ATTNEW',
            eptBanner:0,
            arrBanner:0,
            attBanner:0,
            att2Banner:0,
            usdtBanner:0,
            btc3Banner:0,
            address:sessionStorage.getItem("address"),
            tokenDisable:false,
        }
    },
    activated (){
        this.currentPage = 1;
        if(this.address){
            this.getBanner();
        }
    },
    methods: {
        async toSubmitCharge(event){
            var _this = this
            var toggle = event.currentTarget;
            // 判断用户充入数量大于0
            if(this.inputtopup<=0){
                Toast('请输入或选择转入数量')
                return false;
            }
            if(!this.address){
                Toast('请使用币安智能链链接')
                return false;
            }
            let etherNum = ethers.utils.parseEther (_this.inputtopup.toString());
            // 判断余额
            const gasPrice = await metamaskConfig.provider.getGasPrice();
            if(this.chooseType==0){
                if(parseFloat(this.inputtopup)>parseFloat(this.eptBanner)){
                    Toast('钱包余额不足')
                    return false;
                }
                if(_this.tokenDisable){
                    return false;
                }
                _this.tokenDisable = true;
                $(toggle).css('background','#dddddd');
                await metamaskConfig.eptContract.allowance(this.address,contractConfig.conAddress).then(async res =>{
                    if(parseFloat(ethers.utils.formatEther(res))<=0){
                        await metamaskConfig.eptContract.approve(contractConfig.conAddress,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                            gasPrice: gasPrice,
                            gasLimit:250000,
                        });
                    }
                    let tx = await metamaskConfig.conContract.Recharge(etherNum,this.chooseType,{
                        gasPrice: gasPrice,
                        gasLimit:400000,
                    })
                    if(tx){
                        Toast('操作成功');
                        _this.tokenDisable = false;
                        setTimeout(function () {
                            location.reload();
                        },3000)

                    }
                }).catch(err => {
                    Toast('操作失败')
                    _this.tokenDisable = false;
                    return false;
                });
            }
            else if(this.chooseType==1){
                if(parseFloat(this.inputtopup)>parseFloat(this.arrBanner)){
                    Toast('钱包余额不足')
                    return false;
                }
                if(_this.tokenDisable){
                    return false;
                }
                _this.tokenDisable = true;
                $(toggle).css('background','#dddddd');
                await metamaskConfig.arrContract.allowance(this.address,contractConfig.conAddress).then(async res =>{
                    if(parseFloat(ethers.utils.formatEther(res))<=0){
                        await metamaskConfig.arrContract.approve(contractConfig.conAddress,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                            gasPrice: gasPrice,
                            gasLimit:250000,
                        });
                    }
                    let tx = await metamaskConfig.conContract.Recharge(etherNum,this.chooseType,{
                        gasPrice: gasPrice,
                        gasLimit:400000,
                    })
                    if(tx){
                        Toast('操作成功');
                        _this.tokenDisable = false;
                        setTimeout(function () {
                            location.reload();
                        },3000)

                    }
                }).catch(err => {
                    Toast('操作失败')
                    _this.tokenDisable = false;
                    return false;
                });
            }
            else if(this.chooseType==2){
                if(parseFloat(this.inputtopup)>parseFloat(this.usdtBanner)){
                    Toast('钱包余额不足')
                    return false;
                }
                if(_this.tokenDisable){
                    return false;
                }
                _this.tokenDisable = true;
                $(toggle).css('background','#dddddd');
                await metamaskConfig.usdtContract.allowance(this.address,contractConfig.conAddress).then(async res =>{
                    if(parseFloat(ethers.utils.formatEther(res))<=0){
                        await metamaskConfig.usdtContract.approve(contractConfig.conAddress,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                            gasPrice: gasPrice,
                            gasLimit:250000,
                        });
                    }
                    let tx = await metamaskConfig.conContract.Recharge(etherNum,this.chooseType,{
                        gasPrice: gasPrice,
                        gasLimit:400000,
                    })
                    if(tx){
                        Toast('操作成功');
                        _this.tokenDisable = false;
                        setTimeout(function () {
                            location.reload();
                        },3000)

                    }
                }).catch(err => {
                    Toast('操作失败')
                    _this.tokenDisable = false;
                    setTimeout(function () {
                        location.reload();
                    },3000)
                    return false;
                });
            }
            else if(this.chooseType==3){
                Toast("暂未开放");
                return false;
                if(parseFloat(this.inputtopup)>parseFloat(this.attBanner)){
                    Toast('钱包余额不足')
                    return false;
                }
                if(_this.tokenDisable){
                    return false;
                }
                _this.tokenDisable = true;
                $(toggle).css('background','#dddddd');
                await metamaskConfig.attNewContract.allowance(this.address,contractConfig.attConAddress).then(async res =>{
                    if(parseFloat(ethers.utils.formatEther(res))<=0){
                        await metamaskConfig.attNewContract.approve(contractConfig.attConAddress,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                            gasPrice: gasPrice,
                            gasLimit:250000,
                        });
                    }
                    let tx = await metamaskConfig.attConContract.ATTRecharge(etherNum,{
                        gasPrice: gasPrice,
                        gasLimit:1300000,
                    })
                    if(tx){
                        Toast('操作成功');
                        _this.tokenDisable = false;
                        setTimeout(function () {
                            location.reload();
                        },3000)

                    }
                }).catch(err => {
                    Toast('操作失败')
                    _this.tokenDisable = false;
                    setTimeout(function () {
                        location.reload();
                    },3000)
                    return false;
                });
            }
            else if(this.chooseType==4){
                if(parseFloat(this.inputtopup)>parseFloat(this.btc3Banner)){
                    Toast('钱包余额不足')
                    return false;
                }
                if(_this.tokenDisable){
                    return false;
                }
                _this.tokenDisable = true;
                $(toggle).css('background','#dddddd');
                await metamaskConfig.btcLpContract.allowance(this.address,contractConfig.tradeCon).then(async res =>{
                    if(parseFloat(ethers.utils.formatEther(res))<=0){
                        await metamaskConfig.btcLpContract.approve(contractConfig.tradeCon,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                            gasPrice: gasPrice,
                            gasLimit:250000,
                        });
                    }
                    let tx = await metamaskConfig.tradeContract.BTCRechar(etherNum,{
                        gasPrice: gasPrice,
                        gasLimit:300000,
                    })
                    if(tx){
                        Toast('操作成功');
                        _this.tokenDisable = false;
                        setTimeout(function () {
                            location.reload();
                        },3000)

                    }
                }).catch(err => {
                    Toast('操作失败')
                    _this.tokenDisable = false;
                    setTimeout(function () {
                        location.reload();
                    },3000)
                    return false;
                });
            }
            else if(this.chooseType==5){
                if(parseFloat(this.inputtopup)>parseFloat(this.att2Banner)){
                    Toast('钱包余额不足')
                    return false;
                }
                if(_this.tokenDisable){
                    return false;
                }
                _this.tokenDisable = true;
                $(toggle).css('background','#dddddd');
                await metamaskConfig.att2Contract.allowance(this.address,contractConfig.att2ChargeAddress).then(async res =>{
                    if(parseFloat(ethers.utils.formatEther(res))<=0){
                        await metamaskConfig.att2Contract.approve(contractConfig.att2ChargeAddress,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
                            gasPrice: gasPrice,
                            gasLimit:250000,
                        });
                    }
                    let tx = await metamaskConfig.att2ChargeContract.ATTRecharge(etherNum,5,{
                        gasPrice: gasPrice,
                        gasLimit:600000,
                    })
                    if(tx){
                        Toast('操作成功');
                        _this.tokenDisable = false;
                        setTimeout(function () {
                            location.reload();
                        },3000)

                    }
                })
                    .catch(err => {
                    Toast('操作失败')
                    _this.tokenDisable = false;
                    setTimeout(function () {
                        location.reload();
                    },3000)
                    return false;
                });
            }
        },
        async getBanner(){
            let _this = this;
           /* await metamaskConfig.eptContract.balanceOf(this.address).then( re => {
                _this.eptBanner = ethers.utils.formatEther(re)
            }).catch(err => {
                console.log(err);
                return;
            });
            await metamaskConfig.arrContract.balanceOf(this.address).then( re => {
                _this.arrBanner = ethers.utils.formatEther(re)
            }).catch(err => {
                console.log(err);
                return;
            });*/
            await metamaskConfig.usdtContract.balanceOf(this.address).then( re => {
                _this.usdtBanner = ethers.utils.formatEther(re)
            }).catch(err => {
                console.log(err);
                return;
            });
            await metamaskConfig.attNewContract.balanceOf(this.address).then( re => {
                _this.attBanner = ethers.utils.formatEther(re)
            }).catch(err => {
                console.log(err);
                return;
            });
            await metamaskConfig.att2Contract.balanceOf(this.address).then( re => {
                _this.att2Banner = ethers.utils.formatEther(re)
            }).catch(err => {
                console.log(err);
                return;
            });
            await metamaskConfig.btcLpContract.balanceOf(this.address).then( re => {
                _this.btc3Banner = ethers.utils.formatEther(re)
            }).catch(err => {
                console.log(err);
                return;
            });
        },
        chooseNum(num){
            this.inputtopup = num;
        },
        tochooseType(chooseTypeText){
            this.chooseTypeText = chooseTypeText;
            this.inputtopup = 0;
            if(chooseTypeText=='EPT'){
                this.chooseType=0;
                this.topnum = [
                    '1000','5000','10000', '30000'
                ];
            }else if(chooseTypeText=='ARR'){
                this.chooseType=1;
                this.topnum = [
                    '0.2','0.5','1', '2'
                ];
            }
            else if(chooseTypeText=='USDT'){
                this.chooseType=2;
                this.topnum = [
                    '10','50','300', '500'
                ];
            }
            else if(chooseTypeText=='ATT'){
                this.chooseType=3;
                this.topnum = [
                    '300', '600','1000','3000'
                ];
            }
            else if(chooseTypeText=='BTC3.0 LP'){
                this.chooseType=4;
                this.topnum = [
                    '10', '50','200','500'
                ];
            }
            else if(chooseTypeText=='ATTNEW'){
                this.chooseType=5;
                this.topnum = [
                    '10', '50','200','500'
                ];
            }
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.currentPage = 1
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },
        onLoad() {
            if(this.address){
                this.getDataList();
                this.currentPage++;
            }else{
                this.loading = false;
            }
        },
        getDataList() {
            const params = {
                address:this.address,
                page:this.currentPage,
                limit: '20'
            }
            chargeLog(params).then(res=> {
                if (this.refreshing) {
                    this.recordList = [];
                    this.refreshing = false;
                }
                this.loading = false;
                this.recordList = [...this.recordList, ...res.data.list]
                if(res.data.list.length < 20){
                    this.finished = true;
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.offset {
    margin-top: 32px;
}
.content {
    padding: 10px 32px;
    background: #0C1913;
    min-height: 100vh;
    .topcontent {
        width: 686px;
        min-height: 776px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(16,43,29,1);
        padding: 24px;
        .title {
            color: rgba(214,255,227,1);
            font-size: 32px;
            font-weight: 400;
            font-family: "PingFang SC";
            line-height: 44px;
        }
        .topupnumber {
            margin-top:  16px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .btn {
                color: rgba(139,166,149,1);
                min-width: 140px;
                height: 72px;
                border-radius: 16px;
                opacity: 1;
                border: 2px solid rgba(139,166,149,1);
                text-align: center;
                /*line-height: 72px;*/
                font-size: 32px;
                padding: 0 15px;
                margin-top: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .active{
                opacity: 1;
                border: 1px solid rgba(39,204,127,1);
                background: rgba(39,204,127,0.2);
            }
        }
        .inputcontent {
            margin-top: 16px;
            display: flex;
            justify-content: center;
            .inputClass {
                width: 632px;
                height: 96px;
                background: #29332D;
                border-radius: 16px;
                ::v-deep .van-field__control{
                color: white;
                }
            } 
        }
        .topresult {
            margin-top:16px;
            width: 638px;
            height: 168px;
            border-radius: 16px;
            opacity: 1;
            border: 2px solid rgba(39,204,127,0.2);
            background: rgba(41,51,45,1);
            display: flex;
            justify-content: space-around;
            align-items: center;
            .expectpay {
                .num {
                    color: rgba(214,255,227,1);
                    font-size: 44px;
                    font-weight: 500;
                    font-family: "DIN";
                    text-align: center;
                }
                .txt {
                    color: rgba(214,255,227,1);
                    font-size: 24px;
                    font-weight: 500;
                    font-family: "DIN";
                    text-align: center;
                    line-height: 60px;
                }
            }
        }
        .paybtn {
            margin-top: 48px;
            display: flex;
            justify-content: center;
            .btn {
                width: 320px;
                height: 88px;
                border-radius: 16px;
                opacity: 1;
                background: rgba(39,204,127,1);
                color: rgba(32,40,35,1);
                font-size: 36px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: center;
                line-height: 88px;

            }
            .active{
                opacity: 1;
                border: 1px solid rgba(39,204,127,1);
                background: rgba(39,204,127,0.2);
            }
        }
    }
    .middlecontent {
        margin-top: 24px;
        margin-bottom: 48px;
        color: rgba(182,217,195,1);
        font-size: 24px;
        font-weight: 700;
        text-align: left;
        font-family: "PingFang SC";
    }
    .record {
        border-radius: 24px;
        opacity: 1;
        background: rgba(32,40,35,1);
        padding: 24px;
        .title {
            opacity: 1;
            color: rgba(214,255,227,1);
            font-size: 32px;
            font-weight: 700;
            font-family: "PingFang SC";
        }
        .datalist {
            .date {
                margin-top:32px;
                color: rgba(139,166,149,1);
                font-weight: 400;
                text-align: left;
                line-height: 52px;
                font-size: 40px;
            }
            .detail {
                display: flex;
                justify-content: space-between;
                .topnum {
                    margin-top: 32px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    .num {
                        color: rgba(214,255,227,1);
                        font-size: 44px;
                        font-weight: 500;
                        font-family: "DIN";
                    }
                    .txt {
                        color: rgba(139,166,149,1);
                        font-size: 24px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                        text-align: center;
                        line-height: 36px;
                    }
                    .time {
                        color: rgba(139,166,149,1);
                        font-size: 28px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                    }
                }
                .status {
                    color: rgba(39,204,127,1);
                    font-size: 28px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

}
</style>